import React from 'react';
import './apidiff.scss';
import RawHTML from '../../../../../../components/RawHTML';
import Container from '../../../../../../components/Container';

const html = `<html>
<head>
<link rel="stylesheet" href="apidiff.css" type="text/css" />
<meta charset="utf-8" />
</head>
<body>

<div class="headerFile">
<div class="headerName">PDFNet/PDFNetOBJC.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> +[PTConvert FromTiff:in_data:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">PDFNet/PDFViewCtrl.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status removed">Removed</span> -[PTPDFViewCtrl CloseDocAsync]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTCollaborationManager.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> PTCollaborationServerCommunication.collaborationManager</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">@property (nonatomic, strong, nonnull) PTCollaborationManager *collaborationManager</td></tr>
<tr><th>To</th><td class="declaration">@property (nonatomic, weak, nullable) PTCollaborationManager *collaborationManager</td></tr>
</table>
<br>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTDocumentViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTDocumentViewController.alwaysShowNavigationListsAsModal</div>
<div class="difference"><span class="status added">Added</span> PTDocumentViewController.hidesControlsOnTap</div>
<div class="difference"><span class="status added">Added</span> PTDocumentViewController.automaticallyHideToolbars</div>
</div>


<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> PTDocumentViewController.automaticallyHidesControls</div>
<table>
<tr><th></th><th>Availability</th><th>Deprecation Message</th></tr>
<tr><th>From</th><td>Available</td><td><em>none</em></td></tr>
<tr><th>To</th><td>Deprecated</td><td>Deprecated in Apryse for iOS 7.0.3. Use automaticallyHideToolbars</td></tr>
</table>
<br>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTLineEditTool.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> PTLineEditTool</div>
<table>
<tr><th></th><th>Availability</th><th>Deprecation Message</th></tr>
<tr><th>From</th><td>Available</td><td><em>none</em></td></tr>
<tr><th>To</th><td>Deprecated</td><td>Deprecated in Apryse for iOS 7.0.3. Use PTPolylineEditTool</td></tr>
</table>
<br>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTModel.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status removed">Removed</span> PTModel</div>
<div class="difference"><span class="status removed">Removed</span> PTModel.propertyKeys</div>
<div class="difference"><span class="status removed">Removed</span> PTModel.dictionaryValue</div>
<div class="difference"><span class="status removed">Removed</span> PTModel.localPropertyKeys</div>
<div class="difference"><span class="status removed">Removed</span> PTModel.propertyClasses</div>
<div class="difference"><span class="status removed">Removed</span> +[PTModel propertyKeysForKindOfClass:]</div>
<div class="difference"><span class="status removed">Removed</span> +[PTModel localDictionaryValueWithModel:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTModel initWithDictionary:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTPencilDrawingCreate.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTPencilDrawingCreate.shouldShowToolPicker</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTThumbnailSliderViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTThumbnailSliderViewController.toolbar</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTTool.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> -[PTTool shouldInteractWithForm:onPageNumber:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTToolManager.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> -[PTToolEvents tool:shouldInteractWithForm:onPageNumber:]</div>
<div class="difference"><span class="status added">Added</span> PTToolManager.annotationsSnapToAspectRatio</div>
<div class="difference"><span class="status added">Added</span> PTToolManager.selectAnnotationAfterCreation</div>
<div class="difference"><span class="status added">Added</span> PTToolManager.freehandUsesPencilKit</div>
<div class="difference"><span class="status added">Added</span> -[PTToolManagerDelegate toolManager:shouldInteractWithForm:onPageNumber:]</div>
</div>


<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> -[PTToolManager selectAnnotation:onPageNumber:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (BOOL)selectAnnotation:(nonnull PTAnnot *)annotation onPageNumber:(unsigned long)pageNumber</td></tr>
<tr><th>To</th><td class="declaration">- (BOOL)selectAnnotation:(nullable PTAnnot *)annotation onPageNumber:(unsigned long)pageNumber</td></tr>
</table>
<br>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTToolsSettingsManager.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTToolsSettingsCategoryKey</div>
<div class="difference"><span class="status added">Added</span> PTToolsSettingsSettingKey</div>
<div class="difference"><span class="status added">Added</span> PTToolsSettingsCategoryDefaultValueKey</div>
<div class="difference"><span class="status added">Added</span> PTToolsSettingsSettingNameKey</div>
<div class="difference"><span class="status added">Added</span> PTToolsSettingsCategoryDescriptionKey</div>
<div class="difference"><span class="status added">Added</span> PTToolsSettingsSettingKeyKey</div>
<div class="difference"><span class="status added">Added</span> PTToolsSettingsMinOSKey</div>
<div class="difference"><span class="status added">Added</span> PTToolsSettingsUnavailableKey</div>
<div class="difference"><span class="status added">Added</span> PTToolsSettingsManager</div>
<div class="difference"><span class="status added">Added</span> +[PTToolsSettingsManager sharedManager]</div>
<div class="difference"><span class="status added">Added</span> PTToolsSettingsManager.toolsDefaultSettings</div>
<div class="difference"><span class="status added">Added</span> PTToolsSettingsManager.selectAnnotationAfterCreation</div>
<div class="difference"><span class="status added">Added</span> PTToolsSettingsManager.automaticallyHideToolbars</div>
<div class="difference"><span class="status added">Added</span> PTToolsSettingsManager.javascriptEnabled</div>
<div class="difference"><span class="status added">Added</span> PTToolsSettingsManager.applePencilDrawsInk</div>
<div class="difference"><span class="status added">Added</span> PTToolsSettingsManager.freehandUsesPencilKit</div>
<div class="difference"><span class="status added">Added</span> PTToolsSettingsManager.showThumbnailsInSlider</div>
<div class="difference"><span class="status added">Added</span> PTToolsSettingsManager.stopScreenFromDimming</div>
<div class="difference"><span class="status added">Added</span> -[PTToolsSettingsManager boolForKey:]</div>
<div class="difference"><span class="status added">Added</span> -[PTToolsSettingsManager setBool:forKey:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTToolsSettingsViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTToolsSettingsViewController</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/ToolsDefines.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> #def PT_EXTERN</div>
</div>

</div>
</body>
</html>

`

export default (props) => {
  return (
    <Container className='api-dif'>
      <RawHTML html={html}/>
    </Container>
  )
}